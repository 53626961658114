<template>
  <v-dialog v-model="dialog" class="main-card mb-3 card pa-0 ma-0" max-width="800" persistent>
    <v-card class="card-body pa-0">
      <v-card-title class="card-header pt-2 pr-0">

        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ $t('accounting.lang_waiterSalesPrint') }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">

          <div class="btn-actions-pane-right  actions-icon-btn">
            <v-btn class="elevation-0" color="error"
                   text
                   small @click="close">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-title>
      <v-divider class="pa-0 ma-0"/>

      <v-card-text class="pb-0 pt-8">
        <v-form ref="form" lazy-validation>
          <v-row>

            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="12">
              <v-select v-model="printer" :disabled="this.loadData"
                        :items="this.printers"
                        :label="$t('generic.lang_selectPrinter')" :loading="this.loadData"
                        :rules="[ v => !!v]"
                        dense
                        item-text="name"
                        return-object
                        outlined/>
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="card-footer pt-1 pb-1">
        <v-spacer/>
        <v-btn
            :disabled="!printer||loading||loadData"
            depressed
            color="#FF6D00"
            style="color:white;float:right;"
            @click="printUserReport()">
          {{ $t('erp.lang_print') }}
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>

import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {mapState} from "vuex";
import {printDataFromPrinter} from "../../../plugins/printing/printerController";

export default {
  name: "PrintWaiterSalesComponent",
  props: {
    user: {
      type: Object,
      default: () => {
      }
    },
    start_date: Number,
    end_date: Number,
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data: () => {
    return {
      rooms_loader: false,
      types: [],
      loading: false,
      loadData: false,
      show: false,
      printer: null,
      printerIdChoosen: 0,

    }
  },
  mounted() {

  },

  computed: {
    ...mapState(['pos','api']),
    ...mapState('printer',{
      printers: state => state.printers
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
      this.$emit('closed');
    },
    printSales() {
      console.log(this.user);
    },
    printUserReport() {
      this.loading = true;

      this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.PRINTUSERREPORT, {
        reportType: "X",
        startDate:this.start_date,
        endDate:this.end_date,
        userID:this.user.userID
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {

          // EPSON EPOS PRINT
          printDataFromPrinter(this.printer,res.data.printXML).then(()=>{

          }).catch((err)=>{
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_printProblemConnected'),
              color: "error"
            });
          }).finally(()=>{
            this.loading = false;
          })

        } else {
          this.loading = false;

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_printProblemConnected'),
            color: "error"
          });
        }
      }).catch(() => {
        this.loading = false;

        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_printProblemConnected'),
          color: "error"
        });
      });
    },
  }
}
</script>

<style scoped>
.selected {
  border: 3px solid blue !important;
}
</style>
