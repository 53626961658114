<template>
    <v-row align="center" class="text-center elevation-0 pa-0 ma-0" justify="center">
        <v-col class="mx-auto elevation-0 pa-0 ma-0" cols="12">
            <v-card class="transparent pa-0 ma-0" elevation="0" outlined>
                <div class="card-border pa-0 ma-0 card">
                    <div class="dropdown-menu-header">
                        <div class="dropdown-menu-header-inner bg-primary">
                            <div class="menu-header-content">
                                <div class="avatar-icon-wrapper mb-3 avatar-icon-xl">
                                    <div class="avatar-icon">
                                        <img :alt="this.user && this.user.preName + ' ' + this.user.lastName" :src="this.user && this.user.avatarURI">
                                    </div>
                                </div>
                                <div>
                                    <h5 class="menu-header-title">
                                        {{this.user && this.user.preName + " " + this.user.lastName}}
                                    </h5>
                                    <h6 class="menu-header-subtitle">
                                        {{this.user && this.user.role}}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-col>
        <v-col class="pa-0 ma-0" cols="12">
            <ul class="list-group list-group-flush">
                <li class="bg-warm-flame list-group-item">
                    <div class="widget-content p-0">
                        <div class="widget-content-wrapper">
                            <div class="widget-content-left">
                                <div class="widget-heading text-dark text-left opacity-7">{{ $t('timetrack.lang_timeRecordHistory') }}</div>
                                <div class="widget-subheading opacity-10"><span class="pr-2"><b
                                        class="text-danger">{{times && times.worktime}}</b> {{ $t('timetrack.lang_workTime') }},</span><span><b
                                        class="text-dark">{{times && times.overtime}}</b> {{ $t('timetrack.lang_overTime') }}</span></div>
                            </div>
                            <div class="widget-content-right">
                                <div class="icon-wrapper m-0">
                                    <div class="progress-circle-wrapper">
                                        <vue-circle
                                                v-if="times && times.worktimeNetPourcent"
                                                :animation-start-value="0.0"
                                                :fill="f4"
                                                :progress="times.worktimeNetPourcent"
                                                :reverse="false"
                                                :show-percent="true"
                                                :size="50"
                                                :start-angle="0"
                                                :thickness="3"
                                                empty-fill="rgba(0, 0, 0, .12)"
                                                insert-mode="append"
                                                line-cap="round">
                                        </vue-circle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </v-col>

        <v-col class="text-left scroll-container ps ps--active-y pa-0" cols="12">
            <perfect-scrollbar>
                <v-expansion-panels focusable class="elevation-0" id="expansion">
                    <v-expansion-panel
                            :key="i"
                            v-for="(time,i) in this.pauseTimeJSON"

                    >
                        <v-expansion-panel-header>
                            <span class="primary--text">{{ time.workshift_started }}</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="scroll-area-md">
                                <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">

                                    <div class="vertical-timeline-element" v-if="time.workshift_started">
                                        <div>
                                            <span class="vertical-timeline-element-icon bounce-in">
                                                <i class="badge badge-dot badge-dot-xl badge-primary"> </i>
                                            </span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">{{ $t('timetrack.lang_shiftFor') }} {{dateFormater(new Date(time.workshift_started))}} {{ $t('generic.lang_ShiftStarted2') }}</h4>
                                                <p>
                                                    <strong class="success--text">{{user.preName + " " + user.lastName}}</strong>, {{ $t('timetrack.lang_startThisShift') }} <a href="javascript:void(0);">{{$moment(time.workshift_started, "DD.MM.YYYY HH:mm:ss").from(new Date())}} </a>
                                                </p>
                                                <span class="vertical-timeline-element-date">{{timeFormater(new Date(time.workshift_started))}}</span>
                                            </div>
                                        </div>
                                    </div>


                                    <div :key="key"
                                         v-for="(item , key) in time.pauseTimeJSON">

                                        <!--started time -->
                                        <div class="vertical-timeline-element" v-if="item.start>0">
                                            <div>
                                                <span class="vertical-timeline-element-icon bounce-in">
                                                    <i :class="(item.end > 0)? 'badge badge-dot badge-dot-xl badge-warning' : 'badge badge-dot badge-dot-xl badge-danger'"> </i>
                                                </span>
                                                <div class="vertical-timeline-element-content bounce-in">
                                                    <h4 class="timeline-title">
                                                        the {{ordinalNumbers(key)}} {{ $t('timetrack.lang_pauseIsStarted') }} <span v-if="(item.end > 0)">({{calcWorkTime(item.start*1000,item.end*1000)}})</span>
                                                    </h4>
                                                    <p v-if="(item.end > 0)">{{ $t('generic.lang_pauseFinishedAt') }}: <span class="warning--text">{{dateTimerFormater(new Date(item.end*1000) )}}</span></p>
                                                    <p class="error--text" v-else>{{ $t('timetrack.lang_thisPauseStillOpen') }}</p>
                                                    <span class="vertical-timeline-element-date">{{timeFormater(new Date(item.start*1000))}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="vertical-timeline-element" v-if="time.workshift_ended">
                                        <div>
                                            <span class="vertical-timeline-element-icon bounce-in">
                                                <i class="badge badge-dot badge-dot-xl badge-danger"> </i>
                                            </span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">{{ $t('timetrack.lang_thisShiftIsClosedAt') }} {{dateFormater(new Date(time.workshift_ended))}} </h4>
                                                <p>
                                                    <strong>{{ user.preName + " " + user.lastName}}</strong>, {{ $t('timetrack.lang_closeThisShift') }} <a href="javascript:void(0);">{{$moment(time.workshift_ended, "DD.MM.YYYY HH:mm:ss").from(new Date())}} </a>
                                                </p>
                                                <span class="vertical-timeline-element-date">{{timeFormater(new Date(time.workshift_ended))}}</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </perfect-scrollbar>
        </v-col>


    </v-row>
</template>

<script>
    import {PerfectScrollbar} from "vue2-perfect-scrollbar";
    import VueCircle from 'vue2-circle-progress'

    export default {
        name: "HistoryTimeLine",
        components: {PerfectScrollbar, VueCircle},
        props: {
            user: {
                type: Object,
                default: null
            },
            times: {
                type: Object,
                default: null
            },
            pauseTimeJSON: {
                type: Array,
                default: null
            }
        },
        watch: {
            workshift_ended(val) {
                this.end = val > 0;
            }
        },
        data: () => {
            return {
                started: "",
                ended: "",
                timers: [],
                f4: {gradient: ["#26c62c"]}
            }
        },
        beforeDestroy() {
            this.timers.forEach((item) => {
                clearTimeout(item)
            })
        },
      mounted() {
            this.setStarted();
            this.setEnded();
        },
        methods: {
            setStarted() {
                const self = this;
                this.timers.push(
                    setInterval(function () {
                        if (self.workshift_started) {
                            self.started = self.$moment(self.workshift_started, "DD.MM.YYYY HH:mm:ss").from(new Date());
                        }
                    }, 1))
            },
            setEnded() {
                const self = this;
                this.timers.push(
                    setInterval(function () {
                        if (self.workshift_ended) {
                            self.ended = self.$moment(self.workshift_ended, "DD.MM.YYYY HH:mm:ss").from(new Date());
                        }
                    }, 1))
            },
            calcWorkTime(start, end) {
                const deff = (end - start);
                let s = deff / 1000;
                let hours = Math.floor(s / 3600);
                const minutes = Math.floor((s - (hours * 3600)) / 60);

                let str = (hours < 10 ? "0" + hours : hours) + "H:" + (minutes < 10 ? "0" + minutes : minutes) + "M";
                return str;
            }
            ,
            ordinalNumbers(number) {
                const numbers = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th", "16th", "17th",
                    "18th", "19th", "20th", "21st", "22nd"];
                return numbers[number];
            }
            ,
            dateTimerFormater(date) {
                let str = date.toLocaleDateString() + " " + date.toLocaleTimeString();
                return str
            }
            ,
            dateFormater(date) {
                return date.toLocaleDateString();
            }
            ,
            timeFormater(date) {
                let str = date.toLocaleTimeString();
                return date.getHours() + ":" + date.getMinutes() + " " + str.split(' ')[1];
            }
            ,
        }
    }
</script>

<style scoped src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>
<style scoped>
    .card-details {
        background: transparent !important;

    }

    .ps {
        max-height: 300px;
    }

    #expansion {
        z-index: 0;
    }


</style>