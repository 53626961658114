<template>
  <v-container class="pa-0" fluid>
    <page-title
        :heading="$t('accounting.lang_tipOverview')"
        :icon="icon"
        :subheading="$t('accounting.lang_tipOverview')"
    ></page-title>
    <div class="app-main__inner">
      <waiter-tips-component/>
    </div>
  </v-container>
</template>

<script>
//components
import PageTitle from "../../../Layout/Components/PageTitle";
import WaiterTipsComponent from "@/components/accounting/user/WaiterTipsComponent";

export default {
  name: "WaiterSalesView",
  components: {
    WaiterTipsComponent,
    PageTitle,
  },
  data: () => ({
    icon: "bar_chart icon-gradient bg-tempting-azure"
  })
};
</script>

<style>
</style>
